import React from 'react';
import styles from './styles.module.scss';
//import News from "@components/Home/SubscribeSection/News";
import Blog from "@components/Home/SubscribeSection/Blog";
import Subscribe from "@components/Home/SubscribeSection/Subscribe";

const SubscribeSection = ({ blogList = [] }) => {
  return (
    <section className={styles.section}>
      {/*<News newsList={newsList} />*/}
      <Blog blogList={blogList} />
      <Subscribe />
    </section>
  );
};

export default SubscribeSection;
