import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useWindowDimensions } from 'shared/modules/hooks';
import useTranslation from 'next-translate/useTranslation';
import s from './style.module.scss';
import { IW_CDN_URL } from '@src/constants/settings';
import { IwImage } from '@shared/components/IwImage';

const PopularServices = ({list}) => {
  const { t, lang } = useTranslation('home');
  const counter = list.length;
  const [countOfItems, setCountOfItems] = useState(counter);
  const windowWidth = useWindowDimensions();

  useEffect(() => {
    if (windowWidth <= 768 && windowWidth >= 481) {
      setCountOfItems(5);
    }
    if (windowWidth <= 480) {
      setCountOfItems(3);
    }
  }, [windowWidth]);

  return (
    <div className={'container'}>
      <div className={s.title}>
        <Link href={`/services`} prefetch={false}>
          <a className="link-icon">{t('home.popular_services')}</a>
        </Link>
      </div>
      <div className={s.box}>
        {list.slice(0, countOfItems).map((item, index) => {
          const topBlock = item.type === 'top';
          const newService = item.type === 'service';
          const newProgram = item.type === 'program';
          const exclusive = item.type === 'exclusive';

          let classArray = [s.box_item_card__label];

          if(topBlock) classArray.push(s.box_item_card__top);
          if(newService) classArray.push(s.box_item_card__newService);
          if(newProgram) classArray.push(s.box_item_card__newProgram);
          if(exclusive) classArray.push(s.box_item_card__exclusive);

          return (
            <Link href={`${item.link}`} key={index} target="_blank" passHref prefetch={false}>
              <a className={[s.box_item, item.size ? s.box_item_accent : ''].join(' ')}>

                <div className={s.box_item_card}>
                  {item.size && <p className={s.box_item_card_title}>{item.title}</p>}
                  <div className={s.box_item_card_labs}>
                    <div className={classArray.join(' ')}>
                      {item.text}
                    </div>
                    <div className={s.box_item_card__price} dangerouslySetInnerHTML={{ __html: item.price }} />
                  </div>
                  <div className={s.imgBg}>
                    <IwImage
                      layout="fill"
                      src={`${IW_CDN_URL}/img/popularServices/${item.img}.jpg`}
                      quality="80"
                      alt={item.img}
                    />
                  </div>
                </div>
                {!item.size && (
                  <div className={s.box_item_info}>
                    <p className={s.box_item_info_title}>{item.title}</p>
                    <div className={s.box_item_info_box}>
                      <span className={s.box_item_info_box_title}>
                        {t('home.viewed_by')}: {item.numberOfViews}
                      </span>
                      <div className={s.box_item_info_box_rate}>
                        <span>{t('home.rating')}:</span>
                        <div className={s.box_item_info_box_rate_box}>
                          {item.rate && [...Array(item.rate)].map((e, i) => (
                            <svg
                              key={i}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7.65127 1.19785C7.76743 0.953606 8.11504 0.953605 8.23119 1.19785L10.081 5.08763C10.1278 5.18605 10.2214 5.25401 10.3295 5.26812L14.6005 5.82541C14.8687 5.86041 14.9761 6.191 14.7797 6.37695L11.6519 9.33826C11.5728 9.41319 11.5371 9.52315 11.557 9.63029L12.3468 13.8645C12.3964 14.1304 12.1152 14.3347 11.8777 14.2054L8.09476 12.1458C7.99904 12.0937 7.88342 12.0937 7.7877 12.1458L4.00479 14.2054C3.76726 14.3347 3.48604 14.1304 3.53563 13.8645L4.32543 9.63029C4.34542 9.52315 4.30969 9.41319 4.23054 9.33826L1.10278 6.37695C0.906379 6.191 1.0138 5.86041 1.28198 5.82541L5.55301 5.26812C5.66109 5.25401 5.75462 5.18605 5.80143 5.08763L7.65127 1.19785Z"
                                fill="#FFA500"
                              />
                            </svg>
                          ))}
                          {item.rate && [...Array(5 - item.rate)].map((e, i) => (
                            <svg
                              key={i}
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.06289 1.19785C8.17905 0.953606 8.52666 0.953605 8.64281 1.19785L10.4927 5.08763C10.5395 5.18605 10.633 5.25401 10.7411 5.26812L15.0121 5.82541C15.2803 5.86041 15.3877 6.191 15.1913 6.37695L12.0635 9.33826C11.9844 9.41319 11.9487 9.52315 11.9687 9.63029L12.7585 13.8645C12.8081 14.1304 12.5268 14.3347 12.2893 14.2054L8.50638 12.1458C8.41066 12.0937 8.29504 12.0937 8.19933 12.1458L4.41642 14.2054C4.17888 14.3347 3.89766 14.1304 3.94725 13.8645L4.73705 9.63029C4.75704 9.52315 4.72131 9.41319 4.64217 9.33826L1.5144 6.37695C1.318 6.191 1.42542 5.86041 1.6936 5.82541L5.96464 5.26812C6.07271 5.25401 6.16625 5.18605 6.21305 5.08763L8.06289 1.19785Z"
                                fill="#DDE4EA"
                              />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </a>
            </Link>
          );
        })}
      </div>
      {countOfItems !== counter && (
        <button className={s.button} onClick={() => setCountOfItems(counter)}>
          {t(`showMore`)}
        </button>
      )}
    </div>
  );
};

export default PopularServices;
