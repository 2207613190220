import PropTypes from 'prop-types';
import HomeSearch from './HomeSearch';
import Advantages from './Advantages';
import SubscribeSection from './SubscribeSection';
import PopularServices from './PopularServices';
import useTranslation from 'next-translate/useTranslation';
import LazyLoad from 'react-lazyload';
import dynamic from "next/dynamic";
const VideoBox = dynamic(() => import('shared/modules/VideoBox'));
const VideoSlider = dynamic(() => import('./VideoSlider'), {
  ssr: false,
});
const SliderBestServices = dynamic(() => import('./Sliders/services'), {
  ssr: false,
});
const Media = dynamic(() => import('./Media'));
const Projects = dynamic(() => import('./Projects'), {
  ssr: false,
});

function Home(props) {
  const { t, lang } = useTranslation('home');
  const playlist = {
    ru: [
      {
        link: '_tByBC_CNx0',
      },
      {
        link: 'abNK6ZTyAdw',
      },
    ],
    en: [
      {
        link: 'Fvk7HpMdJdc',
      },
    ],
  };
  const mediaImg = {
    ru: [
      {
        src: '/img/media/forbes.svg',
        link:
          'https://www.forbes.ru/partnerskie-materialy/435301-vtoroe-grazhdanstvo-kak-zashchitit-sebya-i-svoyu-sobstvennost-v',
      },
      {
        src: '/img/media/imi.svg',
        link:
          'https://www.imidaily.com/ten-on-the-weekend/extremely-surprised-the-pandemic-hasnt-dampened-demand-for-eu-residency-10-on-the-weekend-alexey-nosovsky/',
      },
      {
        src: '/img/media/lifefaker.svg',
        link: 'https://lifehacker.ru/pereexat-v-evropu/',
      },
      {
        src: '/img/media/lenta.svg',
        link: 'https://lenta.ru/pressrelease/2021/07/20/iword/',
      },
      {
        src: '/img/media/vokrug-sveta.png',
        link: 'https://www.vokrugsveta.ru/articles/intervyu-s-alekseem-nosovskim-portfel-immigracionnykh-programm-kompanii-iworld-id891930/',
      },
      {
        src: '/img/media/AiF.svg',
        link: 'https://aif.ru/society/people/pereezd_iz_rossii_sposoby_immigracii_marshruty_i_procedura_oformleniya',
      },
      {
        src: '/img/media/MKRU.svg',
        link:
          'https://spb.mk.ru/social/2021/07/29/nachal-svoyu-rabotu-mezhdunarodnyy-migracionnyy-marketpleys-iworldcom.html',
      },
      {
        src: '/img/media/rbk.svg',
        link: 'https://plus.rbc.ru/pressrelease/60ee95237a8aa96703efe21f',
      },
    ],
    en: [
      {
        src: '/img/media/cnn.svg',
        link: 'https://www.cnn.gr/style/story/295650/o-giorgio-armani-apokta-xrysi-visa-sta-hnomena-aravika-emirata',
      },
      {
        src: '/img/media/imi.svg',
        link:
          'https://www.imidaily.com/ten-on-the-weekend/extremely-surprised-the-pandemic-hasnt-dampened-demand-for-eu-residency-10-on-the-weekend-alexey-nosovsky/',
      },
      {
        src: '/img/media/buzzFeed.svg',
        link: 'https://www.buzzfeed.com/untwsdqa/dgu-1aldh0kcwj',
      },
      {
        src: '/img/media/wob.svg',
        link: 'https://www.worldoffshorebanks.com/doing-business-in-uae-best-tax-opportunities-and-pros-and-cons.php',
      },
    ],
  };

  return (
    <>
      <HomeSearch />
      <Advantages />
      <LazyLoad offset={150} once>
        <VideoBox
          slider
          playlist={playlist[lang]}
          title={t('home.video.title')}
          description={t('home.video.description')}
          withBg
          textCenter
        />
      </LazyLoad>
      <PopularServices list={props.mostPopularList} />
      <LazyLoad offset={150} once>
        <Media list={mediaImg[lang]} />
      </LazyLoad>
      <SubscribeSection blogList={props.blogList} />
      <LazyLoad offset={150} once>
        <Projects />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <SliderBestServices />
      </LazyLoad>
      <LazyLoad offset={150} once>
        <VideoSlider />
      </LazyLoad>
    </>
  );
}

Home.propTypes = {
  countryGroupsData: PropTypes.array,
  mostPopularList: PropTypes.array,
  newsList: PropTypes.array,
  blogList: PropTypes.array
};

export default Home;
