import React, {memo} from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';

const BlogList = ({ blogList = [] }) => {
  const { t, lang } = useTranslation('home');
  return (
      <div className={styles.cardNews}>
        <div className={styles.wrap}>
          <div className={styles.title}>{t('home.blog.title')}</div>
          <div className={styles.linkList}>
            {blogList?.map(({ postDate, postName, postTitle }, index) => (
              <a href={`${lang}/blog/${postName}`} key={`new-${index}`}  data-type="home_blog_link" data-content={`home_blog_link__${postName}`}>
                  <p className={styles.field}>{postTitle}</p>
              </a>
            ))}
          </div>
          <a className={styles.styleLinkGeneral}  href={`${lang}/blog`} data-type="home_blogs_show__all__blogs">
            {t('home.blog.allBtn')}
          </a>
        </div>
      </div>
  );
};

export default memo(BlogList);
