import { useState, useEffect } from 'react';

const useLazyLoad = (fullList, LAZY_LENGTH) => {
  const initial = {
    list: fullList.slice(0, LAZY_LENGTH),
    index: 0,
    hasMore: true,
  };

  const [lazyList, setLazyList] = useState(initial);

  useEffect(() => {
    setLazyList(initial);
  }, [fullList]);

  const loadMore = () => {
    if (lazyList.list.length >= fullList.length) {
      setLazyList({ ...lazyList, hasMore: false });
      return;
    }

    setLazyList({
      hasMore: true,
      list: [...lazyList.list, ...fullList.slice(lazyList.index + LAZY_LENGTH, lazyList.index + LAZY_LENGTH * 2)],
      index: lazyList.index + LAZY_LENGTH,
    });
  };

  return [lazyList, loadMore];
};

export default useLazyLoad;
