import Image from 'next/image';
import { IW_CDN_IMAGES_URL } from 'constants/settings';

const myLoader = ({ src, width, quality }) => {
  return `${IW_CDN_IMAGES_URL}/_next/image?url=${src}&w=${width}&q=${quality || 80}`;
};

const blurOption = {
  placeholder: 'blur',
  blurDataURL:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM0LThpDAAD8wGjAyyr4AAAAABJRU5ErkJggg==',
};

const IwImage = (props) => {
  const blur = props.blur ? blurOption : {};
  return <Image loader={myLoader} loading="eager" alt={props.alt} {...props} {...blur} />;
};

export { IwImage };
