import styled from 'styled-components';
import { variables } from '@assets/style/tools/variables';
import { respondTo } from '@assets/style/tools/mixin';
import { Button } from '@modules/Button';

const Filter = {
  Section: styled.section`
    display: grid;
    align-items: center;
    justify-self: center;
    position: relative;
    z-index: 1;
    grid-template-rows: 120px 60px 65px 65px 110px 180px 60px;
    ${respondTo(`md`, `grid-template-rows: 80px 60px 65px 40px 110px 180px 60px;`)}
    ${respondTo(
      '950px',
      `grid-template-columns: 30px auto 30px; 
       grid-template-rows: 50px 50px 30px auto 370px 180px 60px;
       grid-row-gap: 15px;
       `,
    )};
    ${respondTo(`sm`, `grid-template-rows: 50px 90px 60px auto 370px 160px 60px;`)}
    ${respondTo(
      `xsm-m`,
      `grid-template-rows: 40px 80px 60px auto 370px 160px 60px; grid-row-gap: 0; grid-template-columns: 20px auto 20px;`,
    )}
    ${respondTo(
      `xs`,
      `grid-template-rows: 25px 60px 40px auto 300px auto 25px; grid-template-columns: 10px auto 10px;`,
    )}
  `,
  Title: styled.h1`
    grid-row-start: 2;
    display: block;
    width: 100%;
    font-size: 44px;
    line-height: 54px;
    color: white;
    font-weight: 800;
    letter-spacing: 0.04em;
    text-align: center;
    ${respondTo('lg', ' font-size: 40px;line-height: 49px;')};
    ${respondTo('md', ' font-size: 36px;line-height: 44px;')};
    ${respondTo('950px', ' font-size: 32px;line-height: 1.4;grid-column-start:2;')};
    ${respondTo('xsm-m', 'font-size: 24px;line-height: 140%;')};
    ${respondTo('xss', 'font-size: 18px;line-height: 27px;')};
  `,
  SubTitle: styled.h2`
    display: block;
    grid-row-start: 3;
    width: 100%;
    font-size: 36px;
    line-height: 44px;
    color: white;
    text-align: center;
    letter-spacing: 0.04em;
    ${respondTo('lg', ' font-size: 32px;line-height: 39px;')};
    ${respondTo('md', ' font-size: 28px;line-height: 34px;')};
    ${respondTo(
      '950px',
      ' font-size: 22px; line-height: 1.4; grid-column-start:2; font-weight: 400; max-width: 70%; margin: auto;',
    )};
    ${respondTo('xsm-m', 'font-size: 18px; line-height: 130%;')};
    ${respondTo('xss', 'font-size: 16px; line-height: 27px; max-width: 90%;')};
  `,
  AutocompleteContainer: styled.div`
    display: grid;
    grid-row-start: 5;
    grid-row-end: 6;
    width: 100%;
    grid-template-columns: 16% 16% 16% 12%;
    box-sizing: border-box;
    justify-content: center;
    height: 85px;
    div {
      width: 100%;
    }
    input {
      border: none;
      ::-moz-placeholder {
        /* Firefox 19+ */
        line-height: 56px;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        line-height: 56px;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        line-height: 56px;
      }
      
    }
    ${respondTo('hds', `grid-template-columns: 18% 18% 18% 15%`)}
    ${respondTo('lapt', `grid-template-columns: 24% 24% 24% 17%; justify-items: center;`)}
    ${respondTo(
      '950px',
      `grid-column-start:2;
      grid-template-columns:1fr 60% 1fr;
      grid-template-rows:60px 60px 60px 60px;
      grid-row-gap:20px;
      height: auto;

      `,
    )};
    ${respondTo(`767px`, `grid-template-columns:1fr 100% 1fr;`)};
    ${respondTo(`xs`, `grid-template-rows: 50px 50px 50px 50px; grid-row-gap: 16px;`)};
  `,
  Search: styled(Button)`
    min-width: 240px;
    height: 56px;
    border-radius: 0;
    ${respondTo('md', 'min-width:190px;')}
    ${respondTo('950px', 'grid-column-start:2;width:inherit')}
    ${respondTo('sm', ` width:100%;`)};
  `,

  SteperWrap: styled.div`
    grid-row-start: 6;
    grid-row-end: 7;
    display: grid;
    grid-template-columns: 17% 20% 20% 20%;
    justify-content: center;
    justify-self: center;
    grid-column-gap: 40px;
    ${respondTo('lapt', `grid-template-columns: 20% 20% 20% 20%;`)};
    ${respondTo('md', 'grid-template-columns: 40% 40%; grid-row-gap:25px;')};
    ${respondTo('950px', 'grid-column-start:2; grid-row-gap:12px;')};
    ${respondTo(
      'sm',
      `grid-column-start:2;
      grid-template-columns:1fr 1fr 1fr 1fr;
      grid-template-rows:60px 1fr;
      width:60%;
      grid-column-gap:0;

      p {
        grid-column-start: 1;
        grid-column-end: 5;
          }
      div:last-of-type div svg{
            display: none;
          }   
      `,
    )};
    ${respondTo(`767px`, `width:100%;`)}
  `,
  StepCard: styled.div`
    display: grid;
    grid-template-rows: 50px 1fr;
    align-items: center;
    grid-row-gap: 20px;
    color: ${variables.white};
    ${(props) => props.active && `span {opacity: 1;}`};
    ${(props) => props.forLast === 3 && `span:before {display: none;}`};
    /* ${(props) => (props.active ? `border-bottom: 3px solid ${variables.accent} !important;` : ``)}; */
    ${respondTo('sm', `grid-row-start:1; border-bottom: 1px solid #8796A6;`)};
  `,
  NumberBox: styled.div`
    display: grid;
    grid-template-columns: 30px 35px 1fr;
    ${respondTo(`sm`, `grid-template-columns: 40px 36px; grid-column-start: 1;`)}
  `,
  StepNumber: styled.span`
    grid-column-start: 2;
    font-weight: 800;
    font-size: 48px;
    line-height: 59px;
    opacity: 0.4;
    position: relative;
    ${respondTo(`sm`, `grid-column-start:1; line-height: 33px; font-size:40px;`)}
    &:before {
      content: '';
      width: 35px;
      height: 18px;
      position: absolute;
      top: 20px;
      right: -65px;
      background: url(/img/arrow/arrow-right.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      ${respondTo(`sm`, `width: 24px; height: 10px; top: 23px; right: -25px;`)}
    }
  `,
  StepDescription: styled.p`
    font-weight: 500;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.5;
    border-left: 1px solid ${variables.accent};
    box-sizing: border-box;
    color: ${variables.white};
    align-self: stretch;
    ${respondTo('lg', 'font-size: 14px;')};
    ${respondTo(`sm`, `padding-left: 0;`)}
    ${respondTo(
      'sm',
      `
        min-height: 0;
        border-left: 0;
        color: ${variables.white};
      }`,
    )};
  `,
};

export { Filter };
