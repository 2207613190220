import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss'

const Card = Array.apply(null, new Array(4));

const Advantages = () => {
  const { t, lang } = useTranslation('home');

  return (
    <>
      <div className={styles.section}>
        <h2 className={`${styles.title} mb-4`}>{`${t('home.advantages.title')}:`}</h2>
        <div className={`${styles.cardRight}`}>
          {
            Card.map((value, index) => {
              return (
                  <a href={`${lang}/services`} className={`${styles.cardWrap} link-icon`}  key={`advan-${index}`}>
                    <p className={styles.card}>
                      {t(`home.advantages.cards.${index}`)}
                    </p>
                  </a>
              )
            })
          }
        </div>
      </div>
    </>
  );
};
export default Advantages;
