import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SubscribeForm } from 'shared/modules/SubscribeForm';
import styles from './styles.module.scss';

const Subscribe = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.cardSubscribe}>
      <p className={styles.subscribeTitle}>{t('home.news.form.title')}</p>
      <p className={styles.subsPreferences}>{t('home.news.form.description')}</p>
      <SubscribeForm className={styles.formSubscribeStyle} form_name="form_home_page" data-type="home_news_news__subscribe_button" />
    </div>
  );
};

export default Subscribe;
