export default [
  {
    id: 130,
    code:'pt'
  },
  { id: 167,
    code:'tr'
  },
  {
    id: 121,
    code:'ae'
  },
  {
    id: 179,
    code:'me'
  },
  {
    id: 64,
    code:'es'
  },
  {
    id: 175,
    code:'fr'
  },
  {
    id: 147,
    code: "rs"
  }
];
