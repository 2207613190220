import React, {memo, useState} from 'react';
import useTranslation from 'next-translate/useTranslation';
import styles from './HomeSteps.module.scss';
import IconArrow from '@assets/svg/home/arrow-right.svg';

const HomeSteps = () => {
  const STEPS = 4;
  const { t } = useTranslation('home');
  const [selectedStep, setStepper] = useState(0);

  const onChangeStepper = (index) => () => {
    setStepper(index);
  };

  return (
    <div className={styles.steps}>
      <div className={`${styles.steps__wrap} row`}>
        {new Array(STEPS).fill(0).map((value, index) => (
          <dl
            className={`${styles.steps__item} col-3 mb-6 mt-6`}
            key={`stepcard-${index}`}
            onClick={onChangeStepper(index)}>
            <dt
              className={`${styles.steps__number} f-center-space-between mb-4  ${
                selectedStep === index ? styles.isActive : ''
              }`}>
              <span>{index + 1}</span>
              {index + 1 !== STEPS && (
                <svg className={styles.arrow}>
                  <use href="#arrow-right"></use>
                </svg>
              )}
            </dt>
            <dd className={`${styles.steps__description} ${selectedStep === index ? '' : 'md-hide'}`}>
              <div className={`${styles.steps__title} mb-2`}> {t(`home.filter.stepsTitle.${index}`)}</div>
              {t(`home.filter.steps.${index}`)}
            </dd>
          </dl>
        ))}
        <div className={'hidden'}>
          <IconArrow />
        </div>
      </div>
    </div>
  );
};
export default memo(HomeSteps);
