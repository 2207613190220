import { memo } from 'react';
import Select from 'antd/lib/select';
import PropTypes from 'prop-types';
import { Style } from '../style';
import {IW_CDN_URL} from "@src/constants/settings";

const { Option } = Select;

const CountrySelect = ({ list, searchCategories, handleChangeFrom, isLoading, countryKey, title, loader }) => {
  return (
    <Style.Box>
      <Style.Title>{title}</Style.Title>
      <Style.InputWrap>
        <Style.Point />
        <Select
          showSearch
          filterOption={(input, option) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) === 0}
          showArrow={false}
          placeholder={title}
          value={searchCategories[countryKey]}
          allowClear
          popupClassName="DDHome"
          notFoundContent={isLoading ? loader : null}
          role="listbox"
          aria-label="select country"
          onChange={(val) => handleChangeFrom(val, countryKey)}>

          {list.length &&
            list.map(({ name, id, code }, ndx) => {
              return (
                <Option key={ndx} value={id} label={name} role="option">
                  <Style.OptionBox>
                    <Style.Flag bgIcon={`${IW_CDN_URL}/img/flags/${code}.svg`} />
                    {name}
                  </Style.OptionBox>
                </Option>
              );
            })}
        </Select>
      </Style.InputWrap>
    </Style.Box>
  );
};

CountrySelect.defaultProps = {
  list: [],
};

CountrySelect.propTypes = {
  list: PropTypes.array,
  searchCategories: PropTypes.object,
  handleChangeFrom: PropTypes.func,
  isLoading: PropTypes.bool,
  countryKey: PropTypes.string,
  title: PropTypes.string,
  loader: PropTypes.any,
};

export default memo(CountrySelect);
