import styled from 'styled-components';
import { respondTo, simpleIcon } from '@assets/style/tools/mixin';
import { variables } from '@assets/style/tools/variables';
import { Point } from '@icons/Svg/Point';
import { Button } from '@modules/Button';

const Style = {
  Box: styled.div`
    align-self: end;
    display: grid;
    grid-row-gap: 12px;
    ${respondTo('950px', `grid-column-start: 2;`)}
  `,
  Title: styled.span`
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: ${variables.white};
    ${respondTo('950px', `display: none;`)}
  `,
  InputWrap: styled.div`
    position: relative;
    overflow: hidden;
    .ant-select {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: ${variables.black};
      outline: none;
      box-shadow: none;
      :last-child {
        border-right: none;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 56px;
      border: none;
      border-right: 1px solid ${variables.border};
      border-radius: 0;
      padding: 17px 28px 17px 34px;
      outline: none;
      box-shadow: none;
      :after {
        margin: 0;
        font-size: 14px;
        line-height: 140%;
      }
      ${respondTo('xs', `height: 48px; padding: 17px 28px 13px 34px;`)}
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-family: ${variables.font};
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: ${variables.grey};
      opacity: 1;
      left: 34px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
      background: ${variables.white};
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      font-family: ${variables.font};
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: ${variables.black};
      padding: 0;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      padding: 0 0 0 23px;
      height: 56px;
      ${respondTo('xs', `height: 48px;`)}
    }
    ${(props) =>
      props.purpose &&
      `
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
         padding: 17px 10px 13px 16px;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
         padding: 0 0 0 6px;
      }
      

    `}
  `,
  Point: styled(Point)`
    display: block;
    position: absolute;
    z-index: 1;
    top: 19px;
    left: 12px;
    width: 16px;
    height: 16px;
    path {
      fill: ${variables.grey};
    }
  `,
  OptionBox: styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 12px;
    align-items: center;
    font-family: ${variables.font};
    font-size: 14px;
    line-height: 150%;
    color: ${variables.black};
  `,
  Flag: styled.div`
    ${simpleIcon('20px', '12px')};
    background-color: ${variables.lightHover};
    background-image: ${(props) => props.bgIcon && `url(${props.bgIcon})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  `,
  Search: styled(Button)`
    width: 100%;
    height: 56px;
    align-self: end;
    text-align: center;
    ${respondTo('950px', 'grid-column-start: 2; margin-top: 15px; align-self: auto; ')}
    ${respondTo('xs', 'margin-top: 0; height: 48px; ')}
  `,
};
export { Style };
