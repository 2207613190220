import * as React from 'react';

const IconChackMark = React.memo(({ className, size, height = 40, width = 40 }) => {
  const w = size || width;
  const h = size || height;
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.1421 5.85781C30.3646 2.08039 25.3422 0 20 0C14.6577 0 9.63531 2.08039 5.85781 5.85781C2.08039 9.63531 0 14.6578 0 20C0 25.3422 2.08039 30.3646 5.85781 34.1421C9.63531 37.9196 14.6578 40 20 40C25.3422 40 30.3646 37.9196 34.1421 34.1422C37.9196 30.3646 40 25.3422 40 20C40 14.6578 37.9196 9.63539 34.1421 5.85781ZM29.5552 15.2401L18.3782 26.4171C18.1494 26.6459 17.8495 26.7603 17.5496 26.7603C17.2497 26.7603 16.9498 26.6459 16.7209 26.4171L10.4448 20.1411C9.98719 19.6835 9.98719 18.9416 10.4448 18.4838C10.9024 18.0262 11.6445 18.0262 12.1021 18.4838L17.5496 23.9313L27.8979 13.5828C28.3555 13.1252 29.0975 13.1252 29.5552 13.5828C30.0128 14.0405 30.0128 14.7824 29.5552 15.2401Z"
        fill="#3570C9"
      />
    </svg>
  );
});
export { IconChackMark };
